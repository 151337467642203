import { render, staticRenderFns } from "./CourseVoucherList.vue?vue&type=template&id=89bda2a0&scoped=true&"
import script from "./CourseVoucherList.vue?vue&type=script&lang=js&"
export * from "./CourseVoucherList.vue?vue&type=script&lang=js&"
import style0 from "./CourseVoucherList.vue?vue&type=style&index=0&id=89bda2a0&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89bda2a0",
  null
  
)

export default component.exports