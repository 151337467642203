<template>
  <div class="hdTicket">
    <div class="page-header flex">
      <span style="font-size: 16px;">课券管理</span>
      <div>
        <div class="screen-btn ant-menu-item-selected" @click="_makeTodo('add')">
          <a-icon type="plus-circle" />
          <span>新增</span>
        </div>
      </div>
    </div>
    <div class="page-main">
      <div class="screen-list">
        <a-form-model layout="inline" :model="souForm">
          <a-form-model-item label="名称">
            <a-input placeholder="请输入关键字" v-model="souForm.name" allowClear />
          </a-form-model-item>

          <a-form-model-item label="类别">
            <a-select placeholder="请选择" v-model="souForm.type" allowClear>
              <a-select-option value="">全部</a-select-option>
              <a-select-option value="1">课次制</a-select-option>
              <a-select-option value="2">课时制</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="状态">
            <a-select placeholder="请选择" v-model="souForm.status" allowClear>
              <a-select-option value="">全部</a-select-option>
              <a-select-option value="0">正常</a-select-option>
              <a-select-option value="1">过期已删除</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <div class="form-btn">
          <a-button @click="_resetBtn">重置条件</a-button>
          <a-button type="primary" @click="_searchBtn">开始查询</a-button>
        </div>
      </div>
      <div class="todo-content">
        <a-table :columns="columns" :dataSource="infoData" :pagination="false" bordered v-if="infoData">
          <div slot="StatusText" slot-scope="text, row">
            <a-popconfirm title="您确定更改其状态?" @confirm="_setTableRow(row, text)" okText="确定" cancelText="取消">
              <span class="status-icon" :style="text == '禁用' ? 'background: #E81B1C' : 'background: #4199F3'"></span> {{ text }}
            </a-popconfirm>
          </div>
          <div slot="Operation" slot-scope="text, row">
            <a-icon type="form" @click="_makeTodo(row)" class="icon-btn" />
            <a-popconfirm title="您确定要恢复吗?" @confirm="_recoTableRow(row)" okText="确定" cancelText="取消">
              <a-button type="link" v-if="row.DelBoolean == 1">恢复</a-button>
            </a-popconfirm>
            <a-popconfirm title="您确定要删除吗?" @confirm="_delTableRow(row)" okText="确定" cancelText="取消">
              <a-icon type="delete" class="icon-btn" v-if="row.DelBoolean == 0" />
            </a-popconfirm>
          </div>
        </a-table>

        <div style="margin: 20px 0; text-align: right;">
          <a-pagination showQuickJumper :defaultCurrent="1" :total="pageTotal" @change="_PageChange" />
        </div>
      </div>

      <a-drawer :title="isEdit ? '课卷信息' : '添加课卷'" width="1000" :closable="false" :visible="CouserVouceherVisible" @close="couserVouceherClose">
      
          <addCouserVouceher ref="addCouserVouceher" v-if="CouserVouceherVisible" :formData="formData" @visible="couserVouceherClose"></addCouserVouceher>
    
         <div class="footer">
          <a-button style="width: 120px; text-align: center; margin-right: 20px;" @click="onMakeClose">取消 </a-button>
          <a-button type="primary" style="width: 120px; text-align: center;" @click="_saveMakeTodo">提交</a-button>
         </div>
      </a-drawer>
    </div>
  </div>
</template>

<script>
import addCouserVouceher from 'pages/ClassFee/Course/addCouserVouceher'

const columns = [
  {
    title: '名称',
    dataIndex: 'Name',
    align: 'center'
  },
  {
    title: '状态',
    dataIndex: 'StatusText',
    width: 110,
    align: 'center',
    scopedSlots: {
      customRender: 'StatusText'
    }
  },
  {
    title: '课卷类型',
    dataIndex: 'TypeText',
    align: 'center'
  },
  {
    title: '使用时间',
    dataIndex: 'StartTypeText',
    align: 'center'
  },
  {
    title: '是否关联合同',
    dataIndex: 'RelateContractText',
    align: 'center'
  },
  {
    title: '限制类型',
    dataIndex: 'UseLimitText',
    align: 'center'
  },
  {
    title: '限制次数',
    dataIndex: 'UseLimitNum',
    align: 'center'
  },
  {
    title: '可用开始使用时间',
    dataIndex: 'UsableStartDate',
    align: 'center'
  },
  {
    title: '可用结束时间',
    dataIndex: 'UsableEndDate',
    align: 'center'
  },
  {
    title: '适用课程名称',
    dataIndex: 'ApplyToName',
    align: 'center'
  },
  {
    title: '说明',
    dataIndex: 'Remarks',
    align: 'center'
  },
  {
    title: '创建人',
    dataIndex: 'CreateUserNameText',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'Operation',
    align: 'center',
    scopedSlots: {
      customRender: 'Operation'
    }
  }
]
export default {
  name: 'hdTicket',
  components: {
    addCouserVouceher
  },
  data() {
    return {
      columns: columns,
      pageTotal: 0,
      page: 1,
      souForm: {
        name: undefined, //关键字
        type: undefined, // 类别
        status: undefined
      },
      CouserVouceherVisible: false,
      infoData: [],
      isEdit: false,
      formData: {}
    }
  },

  created() {
    this._info()
    // this.infoData = {
    // 	...this.infoData
    // }
  },
  methods: {
    onMakeClose() {
      this.CouserVouceherVisible = false
    },
    // 显示表单
    _makeTodo(row) {
      if (row !== 'add') {
        this.isEdit = true
        this.formData = {
          ...row
        }
      } else {
        this.isEdit = false
        this.formData = {}
      }
      this.CouserVouceherVisible = true
    },
    // 检索
    _searchBtn() {
      this.page = 1
      this._info()
    },
    // 重置
    _resetBtn() {
      this.souForm.name = undefined
      this.souForm.type = undefined
      this.souForm.status = undefined
    },
    // 删除
    _delTableRow(index) {
      this.$axios.post(
        80814007,
        {
          Id: index.Id
        },
        res => {
          if (res.data.code == 1) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.success(res.data.msg)
          }
          this._info()
        }
      )
    },
    _setTableRow(index, text) {
      var Status = 0
      if (text == '禁用') {
        Status = 1
      }
      this.$axios.post(
        80814005,
        {
          Id: index.Id,
          Status: Status
        },
        res => {
          if (res.data.code == 1) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.success(res.data.msg)
          }
          this._info()
        }
      )
    },
    _recoTableRow(index) {
      this.$axios.post(
        80814006,
        {
          Id: index.Id
        },
        res => {
          if (res.data.code == 1) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.success(res.data.msg)
          }
          this._info()
        }
      )
    },
    // 分页事件
    _PageChange(page) {
      this.page = page
      this._info()
    },
    // 关闭表单
    couserVouceherClose(key) {
      if (key === 'actClose') {
        this.page = 1
        this._info()
      }
      this.CouserVouceherVisible = false
    },
    // 获取表格内容
    _info() {
      this.$message.loading('加载中...')

      let data = {
        Type: this.souForm.type,
        DelStatus: this.souForm.status,
        CourseName: this.souForm.name,
        Page: this.page,
        PageSize: 10
      }
      this.$axios.get(80814001, data, res => {
        if (res.data.code == 1) {
          this.infoData = res.data.data
          this.pageTotal = res.data.count
          this.page = this.page
        } else {
          this.pageTotal = 1
          this.infoData = []
        }
        this.$message.destroy()
      })
    },

    // 提交表单
    async _saveMakeTodo() {
      let result1
      try {
        result1 = await this.$refs.addCouserVouceher.onSubmit()
      } catch (error) {
        // console.error(error)
      }

      console.log(result1)
      if (result1) {
        let form = {
          ...result1
        }
        for (const key in form) {
          if (typeof form[key] === 'boolean') {
            form[key] = +form[key]
          }
          if (typeof form[key] === 'object') {
            if (key === 'ApplyTo') {
              form[key] = form[key].join()
              if (form[key] === 'undefined') {
                form[key] = ''
              }
            }
             if (key === 'ApplyAreaId') {
              form[key] = form[key].join()
              if (form[key] === 'undefined') {
                form[key] = ''
              }
            }
          }
        }
        // 如果是编辑
        if (this.formData.Id) {
          form.Id = this.formData.Id
          form.CreateUserId = JSON.parse(sessionStorage.getItem('userInfo')).uid
        }

        this.$axios.post(
          80814004,
          {
            ...form
          },
          res => {
            if (res.data.code == 1) {
              this.$message.success(res.data.msg)
              this.$emit('visible', 'actClose')
              this._info()
            } else {
              this.$message.error(res.data.msg)
            }
          }
        )
      }
    }
  }
}
</script>

<style scoped lang="less">
.hdTicket {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  font-size: 14px;

  .page-header {
    height: 55px;
    line-height: 55px;
    padding: 10px 20px;
    border-bottom: 1px solid #e1e2e3;

    .screen-btn {
      width: 100px;
      display: inline-block;
      font-size: 12px;
      margin-left: 20px;
      border-width: 1px;
      border-style: solid;
      border-radius: 20px;
      padding: 9px 0;
      line-height: 1;
      text-align: center;
      background: #fff !important;
      cursor: pointer;

      i {
        margin-right: 4px;
      }
    }
  }

  .page-main {
    padding: 20px;

    .screen-list {
      margin: 0 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      :deep(.ant-select-selection--single),
      :deep(.ant-form-item-control) {
        min-width: 150px;
      }

      .form-btn {
        button + button {
          margin-left: 10px;
        }
      }
    }
  }

  .icon-btn {
    cursor: pointer;
    margin-left: 10px;
    font-size: 18px;
  }

  .status-icon {
    display: inline-block;
    vertical-align: middle;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
   .drawer-bottom {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            border-top: 1px solid #e9e9e9;
            padding: 10px 16px;
            background: #fff;
            }
    .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: left;
    background: #fff;
    border-radius: 0 0 4px 4px;
    z-index: 10000;
  }
}
</style>
