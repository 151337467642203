<template>
	<div class="rentQi">
		<a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 11 }">
			<a-form-model-item label="课卷名称" prop="Name">
				<a-input v-model="form.Name" allowClear />
			</a-form-model-item>
			<a-form-model-item label="课卷类型" prop="Type">
				<a-select show-search allowClear placeholder="请选择" v-model="form.Type">
					<a-select-option v-for="item in CourseVoucherTypeList" :key="item.value" :value="item.value">
						{{ item.label }}
					</a-select-option>
				</a-select>
			</a-form-model-item>

			<a-form-model-item label="使用时间">
				<a-select show-search allowClear placeholder="请选择" v-model="form.StartType">
					<a-select-option v-for="item in StartTypeList" :key="item.value" :value="item.value">
						{{ item.label }}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label="适用课程">

				<a-select allowClear placeholder="请选择" v-model="form.ApplyTo" mode="multiple" optionFilterProp="label"
					ref="ApplyTo" @change="handleChange('ApplyTo')">
					<a-select-option value="all" label="所有课程">
						所有课程
					</a-select-option>
					<a-select-option v-for="item in curriculumList" :key="item.Id" :label="item.Name">
						{{ item.Name }}
					</a-select-option>
				</a-select>
			</a-form-model-item>
				<a-form-model-item label="适用范围">
				<a-select allowClear placeholder="请选择" v-model="form.EffectArea" @change="EfectAreaChange">
					<a-select-option :value="1">全体</a-select-option>
					<a-select-option :value="2">指定部门</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item label=" " :colon="false" prop="ApplyAreaId" v-show="isShowDepartment">
				<a-tree-select v-model="form.ApplyAreaId" :tree-data="treeData" placeholder="请选择" allowClear
					tree-checkable> </a-tree-select>
			</a-form-model-item>


			<a-form-model-item label="限制类型">
				<a-select allowClear placeholder="请选择" v-model="form.UseLimit">
					<a-select-option :value="0">不限</a-select-option>
					<a-select-option :value="1">周</a-select-option>
					<a-select-option :value="2">月</a-select-option>
				</a-select>
			</a-form-model-item>

			<a-form-model-item label="限制次数">
				<a-input-number v-model="form.UseLimitNum" allowClear style="width:420px;" />
			</a-form-model-item>
			<a-form-model-item label="课券课次">
				<a-input-number v-model="form.LessonNum" allowClear style="width:420px;" />
			</a-form-model-item>
			<a-form-model-item label="有效期(天)">
				<a-input-number v-model="form.Validity" allowClear style="width:420px;" />
			</a-form-model-item>
			<a-form-model-item label="有效期">
				<a-range-picker v-model="form.Effect" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
					:placeholder="['开始时间', '结束时间']" allowClear @change="dateChange" />
			</a-form-model-item>
			<a-form-item label="开启状态">
				<a-radio-group v-model="form.Status">
					<a-radio :value="0">已禁用 </a-radio>
					<a-radio :value="1">已开启 </a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-model-item label="是否关联合同">
				<a-select allowClear placeholder="请选择" v-model="form.RelateContract">
					<a-select-option :value="0">不关联（如活动赠送，转介绍赠送）</a-select-option>
					<a-select-option :value="1">关联（如普通赠课，合同赠课）</a-select-option>
				</a-select>
			</a-form-model-item>

			<a-form-model-item label="备注">
				<a-textarea v-model="form.Remarks" :auto-size="{ minRows: 3, maxRows: 5 }" placeholder="备注" />
			</a-form-model-item>


		</a-form-model>
	</div>
</template>

<script>
	export default {
		name: 'rentQi',

		data() {
			return {

				form: {
					Name: '',
					Type: 1,
					ApplyTo: undefined,
					ApplyToName: undefined,
					ApplyAreaId: undefined,
					UseLimit: 0,
					UseLimitNum: '',
					LessonNum: 0,
					Effect: '',
					StartType: 0,
					Status: 0,
					Remarks: '',
					Validity: 0,
					RelateContract: 0,
					UsableStartDate: '',
					UsableEndDate: '',
					EffectArea:1
				},
				isShowDepartment: false,
				rules: {
					Name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}]


				},
				userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
				curriculumList: [],
				StartTypeList: [],
				CourseVoucherTypeList: [],
				PersonId: [],
				PersonName: [],
				treeData: [],
			}
		},
		props: {
			formData: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		created() {

			// 如果是编辑数据，修正表单数据类型
			if (this.formData.Id) {

				for (const key in this.form) {
					if (typeof this.form[key] === 'boolean') {
						this.form[key] = !!this.formData[key]
					} else if (key === 'ApplyAreaId' || key === 'ApplyTo') {
						this.form[key] = this.formData[key] ? this.formData[key].split(',') : []
							if (this.formData['ApplyAreaId'] != "") {
							this.isShowDepartment = true;
						}
					} else if (key === 'Effect') {
						this.form.Effect = [this.formData.UsableStartDate, this.formData.UsableEndDate]
					} else {
						this.form[key] = this.formData[key]
					}
				}
			}
			console.log(this.form)
			//课卷使用时间
			this.$axios.get(80814002, {
				label: 'FeeClass'
			}, res => {
				if (res.data.code == 1) {
					this.StartTypeList = res.data.data

				}
			})
			//获取课券类型
			this.$axios.get(80814003, {
				label: 'FeeClass'
			}, res => {
				if (res.data.code == 1) {
					this.CourseVoucherTypeList = res.data.data

				}
			})
			// 获取部门
			this.$axios.get(
				5836120, {
					userId: this.userinfo.uid
				},
				res => {
					if (res.data.code == 1) {
						this.treeData = res.data.data.schoollist
						// this.StatusList = res.data.data.statuslist
					}
				}
			)

			this.ClassTypeList()
		},
		methods: {
			handleChange(key) {
				if (this.form[key].some(item => item === 'all')) {
					this.form[key] = ['all']
					this.$refs[key].blur()
				}
			},
			//  
			ClassTypeList() {
				//获取课程类别
				this.$axios.get(80810012, {
					label: 'FeeClass'
				}, res => {
					if (res.data.code == 1) {
						this.curriculumList = [...res.data.data]
					}
				})

			},
				// 适用范围选择事件
			EfectAreaChange(val) {
				let rules = {
					...this.rules
				}
				this.isShowDepartment = val === 2
				if (val !== 2) {
					this.form.ApplyAreaId =[]
					if (rules.ApplyAreaId) {
						this.$refs.ruleForm.clearValidate('ApplyAreaId')
					}

					delete rules.ApplyAreaId
				} else {
					rules.ApplyAreaId = [{
						required: true,
						message: '请选择部门',
						trigger: 'change'
					}]
				}
				this.rules = {
					...rules
				}
			},
			// 日期控件值变化
			dateChange(val) {
				if (val[0]) {
					this.form.UsableStartDate = val[0]
					this.form.UsableEndDate = val[1]
				} else {
					this.form.UsableStartDate = ''
					this.form.UsableEndDate = ''
				}
			},
			handleChange(key, val) {
				if (this.form[key].some(item => item === 'all')) {
					this.form[key] = ['all']
					this.$refs[key].blur()
				}
			},
			onSubmit() {
				return new Promise((resolve, reject) => {
					this.$refs.ruleForm.validate(valid => {
						if (valid) {
							resolve(this.form)
						} else {
							reject(false)
						}
					})
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.rentQi {
		background: #ffffff;
		margin-top: 15px;
		min-height: 800px;
		padding: 15px;
	}
</style>
